import * as React from 'react'
import { Redirect } from 'react-router-dom'
import { useMutation } from 'urql'
import { PageWrapper } from '../../components/Layout'
import { Form, HiddenField } from '../../components/Forms'
import { Sequence } from '../../components/Sequence'
import { useDictionary, useGuest, useSiteData } from '../../providers'
import { registrationMutation } from './queries'
import { onlineValidationSchema } from '../../forms/registration'
import { ContactInfo } from './ContactInfo'
import { GroupCount } from './GroupCount'
import { PickDate } from './PickDate'
import { PickTime } from './PickTime'
import { Review } from './Review'
import { FormWrapper } from './styled'

export interface FormValues {
  firstName: string
  lastName: string
  emailAddress: string
  phone: string
  phoneCountryCode: string
  preferredLanguage: string
  agreeToEmail: boolean
  expectedGroupSize: number
  day: string
  timeSlotId: string
  timeSlotTime: Date
}

export const RegistrationSequence = () => {
  const { currentLanguage } = useDictionary()
  const { guest, setGuest } = useGuest()
  const { registrationFull } = useSiteData()
  const [_, submitRegistration] = useMutation(registrationMutation)

  const handleSubmit = async (values: FormValues) => {
    const result = await submitRegistration(values)
    console.log(result)
    if (result?.data?.registerGuest?.guest) {
      setGuest(result.data.registerGuest)
    }
  }

  const initialValues = {
    expectedGroupSize: 1,
    phoneCountryCode: 'US',
    preferredLanguage: currentLanguage,
  }

  if (registrationFull) {
    return (
      // If all timeslots are full, return to the home screen
      <Redirect to="/" />
    )
  }

  if (guest) {
    return (
      // If the guest has registered, take them to the thank you screen
      <Redirect to="/thank-you" />
    )
  }

  return (
    <PageWrapper>
      <FormWrapper>
        <Sequence initialStep="contactInfo">
          <Form
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={onlineValidationSchema}
          >
            <HiddenField name="preferredLanguage" />
            <Sequence.Step name="contactInfo">
              <ContactInfo
                nextStep="groupCount"
                continueLabel="Continue to Group Size"
              />
            </Sequence.Step>
            <Sequence.Step name="groupCount">
              <GroupCount nextStep="review" />
            </Sequence.Step>

            <Sequence.Step name="pickDate">
              <PickDate />
            </Sequence.Step>

            <Sequence.Step name="pickTime">
              <PickTime />
            </Sequence.Step>

            <Sequence.Step name="review">
              <Review />
            </Sequence.Step>
          </Form>
        </Sequence>
      </FormWrapper>
    </PageWrapper>
  )
}
