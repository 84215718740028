import * as React from 'react'
import { useSequence } from './context'

interface StepProps {
  name: string
  children: React.ReactNode
}

export const Step = ({ name, children }: StepProps) => {
  const { currentStep } = useSequence()
  return currentStep === name ? <>{children}</> : null
}
