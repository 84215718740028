import * as React from 'react'
import { useFormikContext } from 'formik'
import { Redirect } from 'react-router-dom'
import { useQuery, useMutation } from 'urql'
import { PageWrapper } from '../../components/Layout'
import { Form } from '../../components/Forms'
import { Sequence } from '../../components/Sequence'
import { Heading } from '../../components/Text'
import { Column } from '../../components/Layout'
import { GroupCount } from '../RegistrationSequence/GroupCount'
import { PickTime } from '../RegistrationSequence/PickTime'
import { PickDate } from '../RegistrationSequence/PickDate'
import {
  timeSlotQuery,
  TimeSlotQueryResponse,
  cancelReservationQuery,
  updateReservation,
  groupByMagicLink,
  GroupQueryResponse,
} from './queries'
import { updateReservationValidationSchema } from '../../forms/registration'
import { useDictionary, Dictionary } from '../../providers'
import { UpdateReview } from './UpdateReview'

const { useState, useEffect } = React

interface HoistFormValuesProps {
  hoistFormValues: (values: FormValues) => void
}

const HoistFormValues = ({ hoistFormValues }: HoistFormValuesProps) => {
  const { values } = useFormikContext()
  useEffect(() => {
    hoistFormValues(values)
  }, [values])

  return null
}

interface UpdateReservationProps {
  match: {
    params: {
      token?: string
    }
  }
}

export interface FormValues {
  expectedGroupSize?: number
  timeSlotId?: number
  day?: string
}

export const UpdateReservation = ({ match }: UpdateReservationProps) => {
  const { token } = match.params
  const [formValues, hoistFormValues] = useState<FormValues | void>()
  const { dictionary } = useDictionary()
  const [success, setSuccess] = useState(false)
  const [cancelled, setCancelled] = useState(false)

  const [_, submitRegistration] = useMutation(updateReservation)
  const [__, cancelReservation] = useMutation(cancelReservationQuery)
  const variables = { magicLink: token }
  const [result] = useQuery<GroupQueryResponse>({
    query: groupByMagicLink,
    variables,
  })

  const group = result?.data?.groupByMagicLink?.group
  const timeSlot = group?.timeSlot

  const timeSlotChanged = Boolean(
    formValues &&
      timeSlot &&
      formValues?.timeSlotId?.toString() !== timeSlot.id,
  )

  const timeSlotQueryVariables =
    formValues && timeSlotChanged ? { id: formValues.timeSlotId } : {}

  const [timeSlotResponse] = useQuery<TimeSlotQueryResponse>({
    query: timeSlotQuery,
    variables: timeSlotQueryVariables,
    pause: !timeSlotChanged,
  })

  const selectedTimeSlot = timeSlotResponse?.data?.timeSlot || timeSlot
  const remainingInTimeSlot = selectedTimeSlot
    ? selectedTimeSlot.remaining
    : undefined

  if (!token) return <Redirect to="/" />

  const handleSubmit = async (values: any) => {
    const { timeSlotId, expectedGroupSize } = values
    const variables = {
      token,
      timeSlotId,
      expectedGroupSize,
    }
    await submitRegistration(variables)
    // TODO handle errors
    setSuccess(true)
  }

  const handleCancel = async () => {
    const confirmed = confirm(dictionary('update.cancel-confirm'))
    if (confirmed) {
      const variables = {
        groupToken: token,
      }
      const result = await cancelReservation(variables)
      const messages = result?.data?.cancelReservation?.messages
      const errors = messages.filter((m) => m.isError)
      if (!errors.length) {
        setCancelled(true)
      }
    }
  }

  const day = timeSlot
    ? new Date(timeSlot.startTime).getDate().toString()
    : undefined

  const initialValues = group
    ? {
        ...group,
        timeSlotId: timeSlot?.id,
        day,
      }
    : {}

  if (!group) {
    return (
      <PageWrapper>
        <Column width="medium">
          <Heading level={2} align="center">
            <Dictionary dictKey="update.no-group" />
          </Heading>
        </Column>
      </PageWrapper>
    )
  }

  if (cancelled) {
    return (
      <PageWrapper>
        <Column width="medium">
          <Heading level={2} align="center">
            <Dictionary dictKey="update.cancel-success" />
          </Heading>
        </Column>
      </PageWrapper>
    )
  }

  return (
    <PageWrapper>
      <Column width="medium">
        <Heading level={2} align="center">
          <Dictionary dictKey="update.title" />
        </Heading>
        {success ? (
          <Heading mt={4} level={4} align="center">
            <Dictionary dictKey="update.success" />
          </Heading>
        ) : result.fetching ? (
          <Heading mt={4} level={4} align="center">
            <Dictionary dictKey="update.fetching" />
          </Heading>
        ) : (
          <Sequence initialStep="review">
            <Form
              onSubmit={handleSubmit}
              initialValues={initialValues}
              validationSchema={updateReservationValidationSchema(
                remainingInTimeSlot,
              )}
            >
              <HoistFormValues hoistFormValues={hoistFormValues} />
              <Sequence.Step name="groupCount">
                <GroupCount nextStep="review" />
              </Sequence.Step>

              <Sequence.Step name="pickDate">
                <PickDate />
              </Sequence.Step>

              <Sequence.Step name="pickTime">
                <PickTime />
              </Sequence.Step>

              <Sequence.Step name="review">
                <UpdateReview
                  cancelReservation={handleCancel}
                  initialValues={initialValues}
                />
              </Sequence.Step>
            </Form>
          </Sequence>
        )}
      </Column>
    </PageWrapper>
  )
}
