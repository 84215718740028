import * as React from 'react'
import { SequenceProvider } from './context'
import { Step } from './Step'

interface SequenceProps {
  initialStep: string
  children: React.ReactNode
}

export const Sequence = ({ initialStep, children }: SequenceProps) => (
  <SequenceProvider initialStep={initialStep}>{children}</SequenceProvider>
)

Sequence.Step = Step
