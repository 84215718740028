import * as React from 'react'
import { ThemeProvider } from '@xstyled/styled-components'
import { defaultTheme } from './defaultTheme'

interface ThemeProviderProps {
  children: React.ReactNode
}

export const Theme = ({ children }: ThemeProviderProps) => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <>{children}</>
    </ThemeProvider>
  )
}
