import * as React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { RegistrationSequence } from './views/RegistrationSequence'
import { OnlineWelcome } from './views/OnlineWelcome'
import { ThankYou } from './views/ThankYou'
import { UpdateReservation } from './views/UpdateReservation'
import {
  ErrorMonitorProvider,
  SiteDataProvider,
  GuestProvider,
  UrqlProvider,
} from './providers'
import { EN, DictionaryProvider } from './providers/Dictionary'
import { Analytics } from './components/Analytics'
import { Theme, GlobalStyles } from './theme'
import dictionaryData from './data/dictionary.json'

const apiUrl = process.env.API_URL
  ? `${process.env.API_URL}/graphql`
  : 'http://localhost:4000/graphql'

export const Registration = () => (
  <ErrorMonitorProvider>
    <DictionaryProvider dictionaryData={dictionaryData} defaultLanguage={EN}>
      <BrowserRouter>
        <UrqlProvider url={apiUrl}>
          <GuestProvider>
            <SiteDataProvider>
              <Theme>
                <>
                  <GlobalStyles />
                  {/* <Analytics id={undefined} /> */}
                  <Switch>
                    <Route path="/" exact component={OnlineWelcome} />
                    <Route
                      path="/reservation/:token"
                      component={UpdateReservation}
                    />
                    <Route path="/register" component={RegistrationSequence} />
                    <Route path="/thank-you" component={ThankYou} />
                    <Redirect to="/" />
                  </Switch>
                </>
              </Theme>
            </SiteDataProvider>
          </GuestProvider>
        </UrqlProvider>
      </BrowserRouter>
    </DictionaryProvider>
  </ErrorMonitorProvider>
)
