import * as React from 'react'
import { Heading } from './Text'
import styled, {
  css,
  breakpoints,
  DefaultTheme,
} from '@xstyled/styled-components'

interface WrapperProps {
  theme: DefaultTheme
  highlight?: boolean
}

const Wrapper = styled.div`
  ${({ highlight, theme }: WrapperProps) => css`
    border: 1px solid;
    border-color: highlight.0;
    padding: 2;
    margin: 0 auto;
    width: 100%;
    max-width: 400px;
    transition: 0.2s;
    background-color: ${highlight ? theme.colors.primary[0] : ''};
    display: grid;
    grid-gap: 2;
    grid-template-columns: 30px 1fr;
    grid-template-areas:
      'icon labels'
      '. button';

    align-items: center;

    svg {
      fill: highlight.2;
    }

    & + & {
      margin-top: 2;
    }

    ${breakpoints({
      md: css`
        padding: 2 3;
        display: flex;
      `,
    })}
  `}
`

const IconWrapper = styled.div(
  breakpoints({
    xs: css`
      width: 30px;
      height: 30px;
      padding: 1;
    `,
    md: css`
      width: 50px;
      height: 50px;
      padding: 2;
    `,
  }),
)

const ValuesWrapper = styled.div(
  breakpoints({
    xs: css`
      flex-grow: 1;
      text-align: left;
    `,

    md: css`
      margin: 0 2;
    `,
  }),
)

const ValueWrapper = styled.div`
  max-width: 100%;

  & > * {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

interface EditButtonProps {
  disabled?: boolean
}

const EditButton = styled.button`
  ${({ disabled }: EditButtonProps) =>
    breakpoints({
      xs: css`
        color: highlight.1;
        cursor: pointer;
        text-decoration: underline;
        outline: none;
        background: none;
        grid-area: button;
        text-align: left;
        ${disabled
          ? css`
              display: none;
              pointer-events: none;
              color: body.3;
            `
          : ''}
      `,
      md: css`
        text-align: right;
      `,
    })}
`

interface SummaryProps {
  Icon: React.ComponentType
  label: string
  values: string[]
  buttonLabel?: string
  highlight?: boolean
  onButtonClick?: () => void | Promise<void>
  disabled?: boolean
}

export const Summary = ({
  Icon,
  label,
  values,
  highlight,
  buttonLabel,
  onButtonClick,
  disabled,
}: SummaryProps) => (
  <Wrapper highlight={highlight}>
    <IconWrapper>
      <Icon />
    </IconWrapper>
    <ValuesWrapper>
      <Heading level={4} weight={4} mt={0}>
        {label}
      </Heading>
      {values.map((value, index) => (
        <ValueWrapper key={value + index}>
          <Heading level={4} mt={0} mb={0} weight={2}>
            {value}
          </Heading>
        </ValueWrapper>
      ))}
    </ValuesWrapper>
    {buttonLabel && onButtonClick ? (
      <EditButton disabled={disabled} onClick={onButtonClick}>
        {buttonLabel}
      </EditButton>
    ) : null}
  </Wrapper>
)
