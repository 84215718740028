import * as Yup from 'yup'

const contactInfo = {
  /* Step 1 */
  firstName: Yup.string()
    .max(25, 'May not be longer than 25 characters')
    .required('Required'),
  lastName: Yup.string()
    .max(50, 'May not be longer than 25 characters')
    .required('Required'),
  emailAddress: Yup.string()
    .email('Invalid email')
    .required('Required'),
  phone: Yup.string()
    .max(18)
    .required('Required'),
  phoneCountryCode: Yup.string().required('Required'),
  agreeToEmail: Yup.boolean().test(
    'is-agreed',
    'This is required',
    (v) => v === true,
  ),
}

const groupSize = (maxGroupSize = 20) => ({
  /* Step 2 */
  expectedGroupSize: Yup.number()
    .min(1)
    .max(
      maxGroupSize,
      maxGroupSize === 20
        ? 'Maximum group size is 20'
        : `There are only ${maxGroupSize} spaces left in your selected timeslot.`,
    )
    .required('This is required'),
})

const timeSlot = {
  /* Step 3 */
  day: Yup.string().required(),
  /* Step 4 */
  timeSlotId: Yup.string().required('You must select a time slot'),
}

export const addGuestValidationSchema = Yup.object().shape({
  ...contactInfo,
})

export const updateReservationValidationSchema = (maxGroupSize?: number) =>
  Yup.object().shape({
    ...groupSize(maxGroupSize),
    ...timeSlot,
  })

export const onlineValidationSchema = Yup.object().shape({
  ...contactInfo,
  ...groupSize(20),
  // ...timeSlot,
})

export const onsiteValidationSchema = Yup.object().shape({
  ...contactInfo,
})

export const addMinorToGroupValidationSchema = Yup.object().shape({
  firstInitial: Yup.string()
    .required()
    .max(1),
  middleInitial: Yup.string().max(1),
  lastName: Yup.string()
    .required()
    .max(40),
})

export const waiverValidationSchema = Yup.object().shape({
  waiverText: Yup.string().required(),
  signature: Yup.string().required('Required'),
  printName: Yup.string().required(),
  printDate: Yup.string().required(),
})
