import * as React from 'react'
import styled, { Box } from '@xstyled/styled-components'
import { ErrorMessage, useFormikContext } from 'formik'
import SignatureCanvas from 'react-signature-canvas'
import { useDictionary } from '../../../providers'
import { FieldProps } from '../Fields'
import { Label } from '../Fields/styled'
import { Heading } from '../../Text'

const { useRef } = React

type SignatureFieldProps = Omit<FieldProps, 'type' | 'placeholder'>

const Wrapper = styled(Box)`
  margin: 3 auto;
  width: 600px;
`

const CanvasWrapper = styled(Box)`
  width: 100%;
  height: 250px;
  margin-bottom: 1;

  position: relative;
  max-width: calc(100% - 20px);
  margin: 0 auto;
  border: 1px solid;
  border-color: primaryMain;

  canvas {
    position: absolute;
    width: 100%;
    z-index: 10;
    height: 100%;
  }
`

const LabelWrapper = styled.div`
  position: absolute;
  z-index: 10;
  pointer-events: none;
  top: 20px;
  right: 20px;
`

// TODO: disable this field when props.disabled === true
interface FormValues {
  signature: string
}

export function SignatureField(props: SignatureFieldProps) {
  const { label, name, required } = props

  const { errors, values, setValues } = useFormikContext<FormValues>()

  const canvasRef = useRef<HTMLCanvasElement>(null)
  const { dictionary } = useDictionary()

  const handleEnd = () => {
    if (!canvasRef.current) return
    const signatureDataURL = canvasRef.current.toDataURL()
    setValues({ ...values, [name]: signatureDataURL })
  }

  return (
    <Wrapper>
      <CanvasWrapper>
        <LabelWrapper>
          <Label required={required} htmlFor={name}>
            {label}
          </Label>
        </LabelWrapper>
        <SignatureCanvas
          onEnd={handleEnd}
          ref={canvasRef}
          label={dictionary('waiver.sign-here')}
          penColor="black"
          canvasProps={{ className: 'sigCanvas' }}
        />
      </CanvasWrapper>
      {errors[name] ? (
        <Heading level={4} weight={1} mt={1} mb={2} color="red">
          {errors[name]}
        </Heading>
      ) : null}
    </Wrapper>
  )
}
