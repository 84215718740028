import * as React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { Heading } from './Text'

const Inner = styled.div`
  position: absolute;
  opacity: 0;
  white-space: nowrap;
  padding: 2;
  bottom: calc(100% + 12px);
  left: 50%;
  transform: translate3d(-50%, 2px, 0);
  pointer-events: none;
  transition: 0.2s;
  color: highlight.1;
  border: 1px solid;
  border-radius: 2px;

  box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.25);
  background-color: white;

  &:before {
    content: '';
    position: absolute;
    top: calc(100% - 5px);
    left: calc(50% - 5px);
    width: 10px;
    height: 10px;
    z-index: -2;
    color: highlight.1;
    border: 1px solid;
    transform: rotate(45deg);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -3;
    width: 100%;
    height: 100%;
    background-color: white;
  }
`

const Fill = styled.div`
  content: '';
  position: absolute;
  top: calc(100% - 7px);
  left: calc(50% - 5px);
  background-color: white;
  width: 12px;
  height: 12px;
  z-index: 2;
  transform: rotate(45deg);
`

interface WrapperProps {
  relative?: boolean
  active?: boolean
}

const Wrapper = styled.div`
  ${({ active, relative }: WrapperProps) => css`
    position: ${relative ? 'relative' : 'static'};

    &:hover ${Inner} {
      opacity: ${active ? '1' : '0'};
    }
  `}
`
// transform: ${active ? 'translateX(-50%) translateY(-1px)' : 'translateX(-50%) translateY(2px)'};

/**
 * ToolTip
 */

interface TooltipProps {
  children: React.ReactNode
  message: string
  active?: boolean
  relative?: boolean
}

export const Tooltip = ({
  children,
  message,
  active,
  relative,
}: TooltipProps) => (
  <Wrapper active={active} relative={relative}>
    <Inner>
      <Heading m={0} level={5} weight={1}>
        {message}
      </Heading>
      <Fill />
    </Inner>
    {children}
  </Wrapper>
)

Tooltip.defaultProps = {
  active: true,
  relative: false,
}
