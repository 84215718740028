import * as React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from '@xstyled/styled-components'

interface ElementProps {
  level: 'primary' | 'secondary' | 'tertiary'
  disabled?: boolean
  to?: string
}
export const ButtonElement = styled.button`
  ${({ level, disabled }: ElementProps) => css`
    font-family: sans;
    font-size: 2;
    color: white;

    background-color: primaryMain;
    border-radius: 35px;
    margin: 0 auto;
    width: 100%;
    max-width: 340px;
    box-sizing: border-box;
    padding: 0 26px;
    text-align: center;
    align-items: center;
    cursor: pointer;
    display: block;
    height: 55px;
    line-height: 55px;
    text-decoration: none;
    transition: 0.2s;

    & > span {
      transition: 0.2s;
    }

    ${disabled
      ? css`
          pointer-events: none;
          opacity: 0.8;
          & > span {
            opacity: 0.4;
          }
        `
      : ''}

    ${level === 'primary'
      ? css``
      : level === 'secondary'
      ? css``
      : level === 'tertiary'
      ? css`
          background-image: none;
          height: auto;
          line-height: initial;
          padding: 1 2;
          color: body.4;
          background-color: initial;
          border-color: currentColor;
          border: 1px solid;
          width: auto;
          font-size: 3;

          &:hover {
            background-image: none;
          }
        `
      : ''}
  `}
`

const OutlineButtonElement = styled(ButtonElement)`
  background: none;
  border: 1px solid currentColor;
  font-size: 3;
  height: 40px;
  line-height: 40px;
  color: primaryMain;
  width: auto;
  background: none;

  &:hover {
    background: none;
  }
`

interface ButtonProps {
  onClick?: (value: any) => void | Promise<void>
  level?: 'primary' | 'secondary' | 'tertiary'
  label: string
  type: 'button' | 'submit'
  disabled?: boolean
}

export const Button = ({
  disabled,
  label,
  level,
  type,
  onClick,
}: ButtonProps) => (
  <ButtonElement
    disabled={disabled}
    type={type}
    level={level || 'primary'}
    onClick={onClick}
  >
    <span>{label}</span>
  </ButtonElement>
)

Button.defaultProps = {
  level: 'primary',
  type: 'button',
}

interface LinkButtonProps extends Omit<ButtonProps, 'type' | 'onClick'> {
  linkTo: string
}

export const LinkButton = ({
  label,
  disabled,
  level,
  linkTo,
}: LinkButtonProps) => (
  <ButtonElement
    disabled={disabled}
    as={Link}
    to={linkTo}
    level={level || 'primary'}
  >
    <span>{label}</span>
  </ButtonElement>
)

export const OutlineButton = ({
  disabled,
  label,
  type,
  onClick,
}: Omit<ButtonProps, 'level'>) => (
  <OutlineButtonElement
    disabled={disabled}
    type={type}
    level="primary"
    onClick={onClick}
  >
    <span>{label}</span>
  </OutlineButtonElement>
)
