import * as React from 'react'
import { useFormikContext } from 'formik'
import { useSequence } from '../../components/Sequence'
import { Heading } from '../../components/Text'
import { Button } from '../../components/Button'
import { Summary } from '../../components/Summary'
import Person from '../../assets/personIcon.svg'
import Group from '../../assets/groupIcon.svg'
// import Calendar from '../../assets/calendarIcon.svg'
// import Clock from '../../assets/clockIcon.svg'
import { FieldsWrapper } from './styled'
import { FormValues } from './RegistrationSequence'
import {
  formatPhone,
  // formatFullDate,
  // formatStartEndTime,
} from '../../utils/formatting'
import { useSiteData, Dictionary, useDictionary } from '../../providers'

export const Review = () => {
  // const { timeSlots } = useSiteData()
  const { goToStep } = useSequence()
  const formik = useFormikContext<FormValues>()

  const { values } = formik

  const {
    firstName,
    lastName,
    emailAddress,
    phone,
    phoneCountryCode,
    expectedGroupSize,
    // timeSlotId,
  } = values

  // const selectedTimeSlot = timeSlots.find((ts) => ts.id === timeSlotId)

  const detailsValues = [
    `${firstName} ${lastName}`,
    emailAddress,
    formatPhone(phoneCountryCode, phone),
  ]

  const groupValues = [
    [
      expectedGroupSize,
      expectedGroupSize === 1 ? 'person' : 'people',
      'in your party',
    ].join(' '),
  ]

  // const calendarValues = [
  //   selectedTimeSlot
  //     ? formatFullDate(selectedTimeSlot.startTime)
  //     : 'No date selected',
  // ]
  // const timeslotValues = [
  //   selectedTimeSlot
  //     ? formatStartEndTime(selectedTimeSlot.startTime, selectedTimeSlot.endTime)
  //     : 'No timeslot selected',
  // ]
  const goTo = (name: string) => () => goToStep(name)
  const { dictionary } = useDictionary()

  return (
    <div>
      <Heading level={2} align="center">
        <Dictionary dictKey="review.title" />
      </Heading>
      <Heading level={4} align="center" color="body.2">
        <Dictionary dictKey="review.description" />
      </Heading>
      <FieldsWrapper>
        <Summary
          Icon={Person}
          label={dictionary('review.contact.title')}
          values={detailsValues}
          onButtonClick={goTo('contactInfo')}
          buttonLabel={dictionary('review.contact.edit-link')}
        />
        <Summary
          Icon={Group}
          label={dictionary('confirm.party.title')}
          values={groupValues}
          onButtonClick={goTo('groupCount')}
          buttonLabel={dictionary('review.party.edit-link')}
        />
        {/*
          <Summary
          Icon={Calendar}
          label={dictionary('review.date.title')}
          values={calendarValues}
          onButtonClick={goTo('pickDate')}
          buttonLabel={dictionary('review.date.edit-link')}
        />
        <Summary
          Icon={Clock}
          label={dictionary('review.time.title')}
          values={timeslotValues}
          onButtonClick={goTo('pickTime')}
          buttonLabel={dictionary('review.time.edit-link')}
        />
 
          */}
      </FieldsWrapper>
      <Button label={dictionary('review.submit')} type="submit" />
    </div>
  )
}
