import * as React from 'react'
import styled from '@xstyled/styled-components'
import { useErrorMonitor, Dictionary, useDictionary } from '../../providers'
import { useFormikContext } from 'formik'
import {
  Field,
  PhoneField,
  // CountryField,
  // StateField,
} from '../../components/Forms'
import { useSequence } from '../../components/Sequence'
import { Heading } from '../../components/Text'
import { Button } from '../../components/Button'
import { Column } from '../../components/Layout'
import { FormValues } from './RegistrationSequence'
import { FieldsWrapper as BaseFieldsWrapper } from './styled'

const { useState, useEffect } = React

const FieldsWrapper = styled(BaseFieldsWrapper)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3;

  & button,
  & .field {
    grid-column: span 2;
  }

  & .field--firstName,
  & .field--lastName,
  & .field--postalCode,
  & .field--city {
    grid-column: span 1;
  }
`

interface ContactInfoProps {
  nextStep: string
  continueLabel: string
}

export const ContactInfo = ({ nextStep }: ContactInfoProps) => {
  const { errors, values, setTouched, validateForm } = useFormikContext<
    FormValues
  >()
  const { identifyUser } = useErrorMonitor()
  const { goToStep } = useSequence()
  const { dictionary } = useDictionary()

  const [shouldProceed, setShouldProceed] = useState(false)
  const next = async () => {
    setTouched({
      firstName: true,
      lastName: true,
      emailAddress: true,
      phone: true,
      agreeToEmail: true,
    })
    await validateForm()
    setShouldProceed(true)
  }

  useEffect(() => {
    if (shouldProceed === false) return
    if (
      errors.firstName ||
      errors.lastName ||
      errors.emailAddress ||
      errors.phone ||
      errors.agreeToEmail
    ) {
      setShouldProceed(false)
      return
    }
    identifyUser(values.emailAddress)
    goToStep(nextStep)
  }, [shouldProceed, errors])

  const agreeLabel = (
    <>
      {dictionary('contact.consent.text')}{' '}
      <a
        href="https://www.chick-fil-a.com/Legal/Privacy"
        target="_blank"
        rel="noopener noreferrer"
      >
        {dictionary('contact.consent.privacy-policy')}
      </a>
    </>
  )

  return (
    <div>
      <Heading level={2} align="center">
        <Dictionary dictKey="contact.title" />
      </Heading>
      <Column width="medium">
        <FieldsWrapper>
          <Field
            name="firstName"
            label={dictionary('contact.firstname.label')}
            placeholder={dictionary('contact.firstname.placeholder')}
            required
          />
          <Field
            name="lastName"
            label={dictionary('contact.lastname.label')}
            placeholder={dictionary('contact.lastname.placeholder')}
            required
          />
          <Field
            name="emailAddress"
            label={dictionary('contact.email.label')}
            type="email"
            placeholder={dictionary('contact.email.placeholder')}
            required
          />
          <PhoneField
            name="phone"
            label={dictionary('contact.phone.label')}
            required
          />
          <Field
            name="agreeToEmail"
            type="checkbox"
            label={agreeLabel}
            required
          />
        </FieldsWrapper>
        <Button
          type="button"
          onClick={next}
          label={dictionary('contact.continue.button')}
        />
      </Column>
    </div>
  )
}
