import * as React from 'react'
import { useDictionary, Params } from './DictionaryProvider'

interface DictionaryProps {
  dictKey: string
  params?: Params
}

export const Dictionary = ({ dictKey, params }: DictionaryProps) => {
  const { dictionary } = useDictionary()
  const text = dictionary(dictKey, params)
  return <>{text}</>
}
