import * as React from 'react'
import styled from '@xstyled/styled-components'
import { Redirect } from 'react-router-dom'
import { PageWrapper, Column } from '../../components/Layout'
import { Heading } from '../../components/Text'
import { FormWrapper } from '../RegistrationSequence/styled'
import {
  formatFullDate,
  formatStartEndTime,
  hyphenate,
  formatPhone,
} from '../../utils/formatting'
import { useGuest, Dictionary, useDictionary } from '../../providers'

const TicketNumber = styled.div`
  border: dots;
  text-align: center;
  width: 100%;
  padding: 4 2;
`

const Details = styled.div`
  background: beige;
  width: 100%;
  text-align: center;
  margin-top: 4;
  padding: 4 2;
`

const DetailsSection = styled.div`
  & + & {
    margin-top: 3;
  }
`

export const ThankYou = () => {
  const { guest, group } = useGuest()
  // const { dictionary } = useDictionary()
  if (!guest || !group) return <Redirect to="/" />
  // const { firstName, lastName, phone, phoneCountryCode, emailAddress } = guest
  const {
    // timeSlot,
    // expectedGroupSize,
    reservationId,
  } = group
  // const groupSizeString =
  //   expectedGroupSize === 1
  //     ? dictionary('confirm.group.size-single')
  //     : dictionary('confirm.group.size-multiple', {
  //         personCount: expectedGroupSize,
  //       })
  return (
    <PageWrapper>
      <FormWrapper>
        <Column width="medium">
          <Heading level={2} align="center">
            <Dictionary dictKey="register.confirm.title" />
          </Heading>
          <Heading level={4} align="center" color="highlight.3">
            <Dictionary dictKey="register.confirm.description" />
          </Heading>
        </Column>
        <hr />
        <Column width="medium" mt={4}>
          <TicketNumber>
            <Heading level={3} weight={4} mt={0} color="body.1">
              <Dictionary dictKey="confirm-email.ticket.title" />
            </Heading>
            <Heading level={2} weight={4} mt={0}>
              {hyphenate(reservationId, 3)}
            </Heading>

            <Heading level={4} align="center" color="body.2">
              <Dictionary dictKey="register.confirm.ticket-note" />
            </Heading>
          </TicketNumber>

          {/*

          <Details>
            <DetailsSection>
              <Heading level={4} weight={4} mt={0}>
                <Dictionary dictKey="confirm.reservation.title" />
              </Heading>
              <Heading level={4} weight={2} my={0} color="body.1">
                <Dictionary dictKey="confirm.address.street" />
                <br />
                <Dictionary dictKey="confirm.address.nyc" />
              </Heading>
              <Heading level={4} weight={2} mt={1} color="highlight.1">
                <a
                  href="https://goo.gl/maps/GrhkLgWN5q8iXrso7"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Dictionary dictKey="confirm.address.see-map" />
                </a>
              </Heading>
            </DetailsSection>

            <DetailsSection>
              <Heading level={4} weight={4} mt={0}>
                <Dictionary dictKey="confirm.address.title" />
              </Heading>

              <Heading level={4} weight={2} my={0} color="body.1">
                {firstName} {lastName}
              </Heading>
              <Heading level={4} weight={2} my={0} color="body.1">
                {emailAddress}
              </Heading>
              <Heading level={4} weight={2} my={0} color="body.1">
                {formatPhone(phoneCountryCode, phone)}
              </Heading>
            </DetailsSection>

            {timeSlot ? (
              <DetailsSection>
                <Heading level={4} weight={4} mt={0}>
                  <Dictionary dictKey="confirm.reservation.title" />
                </Heading>
                <Heading level={4} weight={2} my={0} color="body.1">
                  {formatFullDate(timeSlot.startTime)}
                </Heading>
                <Heading level={4} weight={2} my={0} color="body.1">
                  {formatStartEndTime(timeSlot.startTime, timeSlot.endTime)}
                </Heading>
              </DetailsSection>
            ) : null}

            <DetailsSection>
              <Heading level={4} weight={4} mt={0}>
                <Dictionary dictKey="confirm.group.title" />
              </Heading>
              <Heading level={4} weight={2} my={0} color="body.1">
                {groupSizeString}
              </Heading>
            </DetailsSection>
          </Details>

            */}
        </Column>
      </FormWrapper>
    </PageWrapper>
  )
}
