import * as React from 'react'
import { useQuery } from 'urql'
import { unwindEdges } from '@good-idea/unwind-edges'
import { TimeSlot } from '../../types'
import { timeSlotsQuery, TimeSlotsQueryResponse } from './queries'

interface SiteDataContextValue {
  fetching: boolean
  timeSlots: TimeSlot[]
  registrationFull: boolean
}

const SiteDataContext = React.createContext<SiteDataContextValue | undefined>(
  undefined,
)

export const SiteDataConsumer = SiteDataContext.Consumer

export const useSiteData = () => {
  const ctx = React.useContext(SiteDataContext)
  if (!ctx)
    throw new Error('useSiteDataContext must be used within a SiteDataProvider')
  return ctx
}

interface SiteDataProps {
  children: React.ReactNode
}

export const SiteDataProvider = ({ children }: SiteDataProps) => {
  const [result] = useQuery<TimeSlotsQueryResponse>({
    query: timeSlotsQuery,
  })
  const { fetching, data } = result

  const timeSlots = unwindEdges(data ? data.timeSlots : undefined)[0]

  const registrationFull =
    process.env.REGISTRATION_FULL === 'true' ||
    window.location.search.includes('REG_FULL=true')
      ? true
      : fetching
      ? false
      : timeSlots
          .filter((ts) => new Date(ts.endTime) > new Date())
          .filter((ts) => ts.remaining > 0).length === 0

  const value = {
    fetching,
    timeSlots,
    registrationFull,
    /* */
  }

  return (
    <SiteDataContext.Provider value={value}>
      {children}
    </SiteDataContext.Provider>
  )
}
