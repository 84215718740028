import * as React from 'react'
import styled, { Box } from '@xstyled/styled-components'
import { Heading } from '../../components/Text'
import { Button } from '../../components/Button'
import { Column } from '../../components/Layout'
import { Dictionary, useDictionary } from '../../providers'

const ButtonsWrapper = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2;
  margin: 4 0;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: flex;

    & > * {
      margin: 0 1;
    }
  }
`

interface AgeVerificationProps {
  onConfirm: () => void
}

export const AgeVerification = ({ onConfirm }: AgeVerificationProps) => {
  const [isUnderage, setIsUnderage] = React.useState<boolean | void>(undefined)
  const handleNoClick = () => setIsUnderage(true)
  const { dictionary } = useDictionary()

  return (
    <Column width="medium" textAlign="center">
      <Heading level={2}>
        <Dictionary dictKey="18plus.areyou" />
      </Heading>

      <ButtonsWrapper mb={4}>
        <Button
          level="secondary"
          label={dictionary('global.options.no')}
          onClick={handleNoClick}
        />
        <Button
          level="primary"
          label={dictionary('global.options.yes')}
          onClick={onConfirm}
        />
      </ButtonsWrapper>

      {isUnderage ? (
        <Heading level={3}>
          <Dictionary dictKey="18plus.validation" />
        </Heading>
      ) : null}
      <Heading level={5} mt={4} color="body.2">
        <Dictionary dictKey="18plus.legal" />
      </Heading>
    </Column>
  )
}
