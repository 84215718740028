import gql from 'graphql-tag'
import { groupFragment, guestFragment } from '../../queries'

const registerGuestPayloadFragment = gql`
  fragment RegisterGuestPayloadFragment on RegisterGuestPayload {
    messages {
      message
      isError
      fieldName
      errorType
    }
    group {
      ...GroupFragment
    }
    guest {
      ...GuestFragment
    }
  }
  ${guestFragment}
  ${groupFragment}
`

export const onsiteRegistrationMutation = gql`
  mutation RegisterGuestOnsite(
    $firstName: String!
    $lastName: String!
    $emailAddress: String!
    $agreeToEmail: Boolean!
    $phone: String
    $phoneCountryCode: String
    $preferredLanguage: String
  ) {
    registerGuestOnsite(
      input: {
        firstName: $firstName
        lastName: $lastName
        emailAddress: $emailAddress
        phone: $phone
        phoneCountryCode: $phoneCountryCode
        agreeToEmail: $agreeToEmail
        preferredLanguage: $preferredLanguage
      }
    ) {
      ...RegisterGuestPayloadFragment
    }
  }
  ${registerGuestPayloadFragment}
`

export const registrationMutation = gql`
  mutation RegisterGuest(
    $firstName: String!
    $lastName: String!
    $emailAddress: String!
    $agreeToEmail: Boolean!
    $phone: String
    $phoneCountryCode: String
    $expectedGroupSize: Int!
    $preferredLanguage: String
  ) {
    registerGuest(
      input: {
        firstName: $firstName
        lastName: $lastName
        emailAddress: $emailAddress
        phone: $phone
        phoneCountryCode: $phoneCountryCode
        expectedGroupSize: $expectedGroupSize
        agreeToEmail: $agreeToEmail
        preferredLanguage: $preferredLanguage
      }
    ) {
      ...RegisterGuestPayloadFragment
    }
  }
  ${registerGuestPayloadFragment}
`
