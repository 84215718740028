import gql from 'graphql-tag'
import { TimeSlot } from '../../types'
import { Paginated } from '@good-idea/unwind-edges'

export interface TimeSlotsQueryResponse {
  timeSlots: Paginated<TimeSlot>
}

export const timeSlotsQuery = gql`
  query TimeSlots {
    timeSlots {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          startTime
          endTime
          capacity
          remaining
        }
      }
    }
  }
`
