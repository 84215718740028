import gql from 'graphql-tag'

export const guestFragment = gql`
  fragment GuestFragment on Guest {
    id
    firstName
    lastName
    emailAddress
    phone
    phoneCountryCode
  }
`

export const minorFragment = gql`
  fragment MinorFragment on Minor {
    id
    firstInitial
    middleInitial
    lastName
    guardian {
      ...GuestFragment
    }
  }
  ${guestFragment}
`

export const groupFragment = gql`
  fragment GroupFragment on Group {
    id
    reservationId
    checkinTime
    expectedGroupSize
    magicLinkToken
    timeSlot {
      id
      startTime
      endTime
      remaining
    }
    minors {
      edges {
        node {
          ...MinorFragment
        }
      }
    }
    guests {
      edges {
        node {
          ...GuestFragment
        }
      }
    }
  }
  ${guestFragment}
  ${minorFragment}
`
