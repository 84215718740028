import * as React from 'react'
import styled, { css, Box } from '@xstyled/styled-components'
import { Dictionary } from '../../../providers'
import { FieldProps } from '../Fields'
import { Tooltip } from '../../Tooltip'
import { Heading } from '../../Text'

interface CellProps {
  header?: boolean
}

const Cell = styled.div`
  ${({ header }: CellProps) => css`
    ${header
      ? css`
          height: 42px;
          background-color: body.5;
        `
      : css`
          height: 55px;
        `}

    display: flex;
    font-size: 4;
    align-items: center;
    justify-content: center;
    &:nth-child(-n + 7) {
    }
  `}
`

const CalendarBox = styled.div`
  margin-top: 2;
  border: 1px solid;
  border-color: higlight.0;
  max-width: 375px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(7, 1fr);

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    max-width: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & > * {
      margin: 0;
      padding: 0;
      width: 53px;
    }
  }
`

interface DayButtonProps {
  hasAvailableTimeSlots: boolean
  disabled?: boolean
  dayIsPast: boolean
}

const DayButton = styled.button`
  ${({ disabled, dayIsPast, hasAvailableTimeSlots }: DayButtonProps) => css`
    display: block;
    width: 25px;
    height: 25px;
    font-size: inherit;
    border-radius: 25px;
    ${dayIsPast
      ? css`
          color: body.4;
          pointer-events: none;
        `
      : disabled
      ? css`
          color: highlight.0;
          pointer-events: none;
          text-decoration: line-through;
        `
      : hasAvailableTimeSlots
      ? css`
          color: body.1;

          &:hover {
            border: 1px solid;
          }
        `
      : css`
          color: body.4;
          pointer-events: none;
        `}
  `}
`

const noop = () => {}

interface Day {
  date: number
  onClick: () => void
  dayIsPast: boolean
  enabled: boolean
  hasAvailableTimeSlots: boolean
}

interface CalendarFieldProps extends Omit<FieldProps, 'type' | 'placeholder'> {
  days: Day[]
}

interface CalendarFieldValues {
  day: string
}

export const CalendarField = ({ label, days }: CalendarFieldProps) => {
  return (
    <Box mt={4}>
      <Heading level={2} weight={1} color="body.2" align="center">
        {label}
      </Heading>
      <CalendarBox>
        <Cell header>
          <Dictionary dictKey="date.calendar.su" />
        </Cell>
        <Cell header>
          <Dictionary dictKey="date.calendar.mo" />
        </Cell>
        <Cell header>
          <Dictionary dictKey="date.calendar.tu" />
        </Cell>
        <Cell header>
          <Dictionary dictKey="date.calendar.we" />
        </Cell>
        <Cell header>
          <Dictionary dictKey="date.calendar.th" />
        </Cell>
        <Cell header>
          <Dictionary dictKey="date.calendar.fr" />
        </Cell>
        <Cell header>
          <Dictionary dictKey="date.calendar.sa" />
        </Cell>
        {days.map(
          ({
            date,
            onClick,
            enabled: buttonEnabled,
            dayIsPast,
            hasAvailableTimeSlots,
          }) => (
            <Cell key={date}>
              <Tooltip
                relative
                active={buttonEnabled && hasAvailableTimeSlots === false}
                message="No timeslots available"
              >
                <DayButton
                  disabled={buttonEnabled === false}
                  dayIsPast={dayIsPast}
                  onClick={
                    buttonEnabled && hasAvailableTimeSlots ? onClick : noop
                  }
                  hasAvailableTimeSlots={hasAvailableTimeSlots}
                >
                  {date}
                </DayButton>
              </Tooltip>
            </Cell>
          ),
        )}
      </CalendarBox>
    </Box>
  )
}
