import * as React from 'react'
import * as ReactDom from 'react-dom'
import { Registration } from './Registration'

const renderApp = () =>
  ReactDom.render(
    React.createElement(Registration, null),
    document.getElementById('root') as HTMLElement,
  )

renderApp()
