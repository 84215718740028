import * as React from 'react'
import { Box } from '@xstyled/styled-components'
import { useFormikContext } from 'formik'
import { useSequence } from '../../components/Sequence'
import { Heading } from '../../components/Text'
import { Button } from '../../components/Button'
import { Summary } from '../../components/Summary'
import Group from '../../assets/groupIcon.svg'
import Calendar from '../../assets/calendarIcon.svg'
import Clock from '../../assets/clockIcon.svg'
import { Column } from '../../components/Layout'
import { isMatch, formatFullDate, formatStartEndTime } from '../../utils'
import { useSiteData, Dictionary, useDictionary } from '../../providers'
import { FormValues } from './UpdateReservation'

interface UpdateReviewProps {
  initialValues: FormValues
  cancelReservation: () => void
}

export const UpdateReview = ({
  cancelReservation,
  initialValues,
}: UpdateReviewProps) => {
  const { goToStep } = useSequence()
  const { values } = useFormikContext()
  const { dictionary } = useDictionary()
  const { registrationFull, timeSlots } = useSiteData()

  const { expectedGroupSize, timeSlotId } = values

  const goTo = (name: string) => () => goToStep(name)

  const groupValues = [
    [
      expectedGroupSize,
      expectedGroupSize === 1 ? 'person' : 'people',
      'in your party',
    ].join(' '),
  ]

  const selectedTimeSlot = timeSlots.find((ts) => ts.id === timeSlotId)

  const calendarValues = [
    selectedTimeSlot
      ? formatFullDate(selectedTimeSlot.startTime)
      : 'No date selected',
  ]
  const timeslotValues = [
    selectedTimeSlot
      ? formatStartEndTime(selectedTimeSlot.startTime, selectedTimeSlot.endTime)
      : 'No timeslot selected',
  ]

  const notYetSubmitted = isMatch(values, initialValues)

  return (
    <div>
      <Column width="medium" mt={4}>
        <Heading mb={3} level={4} align="center" color="body.2">
          {notYetSubmitted ? (
            <Dictionary dictKey="update.current-details" />
          ) : (
            <Dictionary dictKey="update.new-details" />
          )}
        </Heading>
        <Summary
          Icon={Group}
          label={dictionary('confirm.party.title')}
          values={groupValues}
          onButtonClick={goTo('groupCount')}
          buttonLabel={dictionary('review.party.edit-link')}
          disabled={registrationFull}
        />
        {/*
        <Summary
          Icon={Calendar}
          label={dictionary('review.date.title')}
          values={calendarValues}
          onButtonClick={goTo('pickDate')}
          buttonLabel={dictionary('review.date.edit-link')}
          disabled={registrationFull}
        />
        <Summary
          Icon={Clock}
          label={dictionary('review.time.title')}
          values={timeslotValues}
          onButtonClick={goTo('pickTime')}
          buttonLabel={dictionary('review.time.edit-link')}
          disabled={registrationFull}
        />
          */}
        {registrationFull ? (
          <Heading mt={3} align="center" level={3}>
            <Dictionary dictKey="update.registration-full" />
          </Heading>
        ) : null}
        {notYetSubmitted ? null : (
          <Box mt={3}>
            <Button type="submit" label={dictionary('update.submit')} />
          </Box>
        )}
        <Box mt={5}>
          <Button
            level="tertiary"
            type="button"
            onClick={cancelReservation}
            label={dictionary('update.cancel')}
          />
        </Box>
      </Column>
    </div>
  )
}
