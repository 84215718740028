import * as React from 'react'
import { Column } from '../components/Layout'
import { Heading } from '../components/Text'
import { Dictionary } from '../providers'

export const RegistrationFull = () => (
  <Column width="m-large" mt={5} textAlign="center">
    <Heading level={2}>
      <Dictionary dictKey="prereg.full.title" />
    </Heading>

    <Heading level={4} mt={3} color="body.2">
      <Dictionary dictKey="prereg.full.dates" />
    </Heading>

    <Heading level={4} mt={3} color="body.2">
      <Dictionary dictKey="prereg.full.subtitle" />
    </Heading>

    <Heading level={3} mt={4} color="body.2" weight={2}>
      <Dictionary dictKey="confirm.address.street" />
      <br />
      <Dictionary dictKey="confirm.address.nyc" />
      <br />
      <a
        href="https://goo.gl/maps/GrhkLgWN5q8iXrso7"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Dictionary dictKey="confirm.address.see-map" />
      </a>
    </Heading>
  </Column>
)
