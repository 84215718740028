import * as React from 'react'
import styled, { css, breakpoints } from '@xstyled/styled-components'
import { Heading } from '../components/Text'
import { EN, ES, useDictionary, Dictionary } from '../providers'

export const Wrapper = styled.footer(
  breakpoints({
    xs: css`
      padding: 2 3;
      align-items: center;
      margin-left: -20px;
      background-color: body.7;
      bottom: 0;
      left: 0;
      width: calc(100% + 40px);
      text-align: left;
      font-size: 5;
      color: body.2;
    `,

    sm: css`
      flex-direction: row;
    `,
  }),
)

const FooterBottom = styled.div(
  breakpoints({
    xs: css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      & > * {
        margin: 1 2;
      }
    `,
    sm: css`
      flex-direction: row;
      & > * {
        margin: 0 2;
      }
    `,
  }),
)

interface LangButtonProps {
  enabled: boolean
}

const LangButton = styled.button`
  ${({ enabled }: LangButtonProps) => css`
    background-color: transparent;
    font-size: inherit;
    color: inherit;
    ${enabled
      ? css`
          color: highlight.3;
          cursor: auto;
        `
      : css`
          color: higlight.0;
          cursor: pointer;
          text-decoration: underline;
        `}
  `}
`

const LanguageSwitcher = () => {
  const { setLanguage, currentLanguage } = useDictionary()

  const setEn = () => setLanguage(EN)
  const setEs = () => setLanguage(ES)

  return (
    <Heading level={4} color="highlight.1">
      <Dictionary dictKey="language" />:{' '}
      <LangButton onClick={setEn} enabled={currentLanguage === EN}>
        <Dictionary dictKey="languages.english.short" />
      </LangButton>
      {' / '}
      <LangButton onClick={setEs} enabled={currentLanguage === ES}>
        <Dictionary dictKey="languages.spanish.short" />
      </LangButton>
    </Heading>
  )
}

export const Footer = () => (
  <Wrapper>
    <FooterBottom>
      <Heading mb={0} level={4} color="body.3">
        <a
          href="https://www.chick-fil-a.com/Legal/Privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Dictionary dictKey="footer.privacyPolicy" />
        </a>
      </Heading>
      <LanguageSwitcher />
    </FooterBottom>
  </Wrapper>
)
