import * as React from 'react'
import { Guest, Group } from '../types'

interface GuestAndGroup {
  guest?: Guest
  group?: Group
}

interface GuestContextValue extends GuestAndGroup {
  setGuest: (input: GuestAndGroup) => void
  updateGuest: (input: GuestAndGroup) => void
}

const GuestContext = React.createContext<GuestContextValue | undefined>(
  undefined,
)

export const GuestConsumer = GuestContext.Consumer

export const useGuest = () => {
  const ctx = React.useContext(GuestContext)
  if (!ctx)
    throw new Error('useGuestContext must be used within a GuestProvider')
  return ctx
}

interface GuestProps {
  children: React.ReactNode
}

export const GuestProvider = ({ children }: GuestProps) => {
  const [state, setGuestState] = React.useState<GuestAndGroup>({})

  const setGuest = (newValues: GuestAndGroup) => setGuestState(newValues)

  const updateGuest = (newValues: GuestAndGroup) =>
    setGuestState({ ...state, ...newValues })

  const value = {
    updateGuest,
    setGuest,
    ...state,
  }

  return <GuestContext.Provider value={value}>{children}</GuestContext.Provider>
}
