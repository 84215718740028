import * as React from 'react'
import styled, { css, Box } from '@xstyled/styled-components'
import { useFormikContext } from 'formik'
import { useSequence } from '../../components/Sequence'
import { Heading } from '../../components/Text'
import { Summary } from '../../components/Summary'
import { Column } from '../../components/Layout'
import { Button } from '../../components/Button'
import { BackButton } from '../../components/Navigation'
import Calendar from '../../assets/calendarIcon.svg'
import { TimeSlot } from '../../types'
import { FormValues } from './RegistrationSequence'
import { formatFullDate, formatStartEndTime } from '../../utils/formatting'
import { useSiteData, Dictionary, useDictionary } from '../../providers'

interface PickTimeProps {
  fetching: boolean
  timeSlots: TimeSlot[]
}

const UrlSpan = styled.span``

const TimeSlotButton = styled.button`
  ${({ disabled }) => css`
    ${disabled
      ? css`
          color: body.3;
          pointer-events: none;
        `
      : css`
          color: red;
          cursor: pointer;
        `}
    cursor: pointer;
    text-decoration: underline;
    margin: 2 auto;
  `}
`

export const PickTime = () => {
  const { timeSlots } = useSiteData()
  const { goToStep } = useSequence()
  const { setValues, values } = useFormikContext<FormValues>()

  const selectTimeSlot = (id: string) => () => {
    setValues({ ...values, timeSlotId: id })
    goToStep('review')
  }

  const goToPickDate = () => goToStep('pickDate')
  const { dictionary } = useDictionary()

  const calendarValues = [
    values.day
      ? formatFullDate(`12/${values.day}/2019 12:00`)
      : 'No date selected',
  ]

  const timeSlotsForPickedDay = timeSlots
    .filter((ts) => new Date(ts.startTime).getDate().toString() === values.day)
    .map((ts) => {
      const timeSlotIsPast = new Date(ts.endTime) < new Date()
      return {
        label: formatStartEndTime(ts.startTime, ts.endTime),
        id: ts.id,
        timeSlotIsPast,
        disabled: ts.remaining - values.expectedGroupSize < 0 || timeSlotIsPast,
        onClick: selectTimeSlot(ts.id),
        remainingLabel:
          ts.remaining - values.expectedGroupSize < 0
            ? 'No timeslots available'
            : dictionary('time.slot.left', {
                spaces: Math.max(0, ts.remaining),
              }),
      }
    })
    .filter((ts) => ts.timeSlotIsPast !== true)

  return timeSlotsForPickedDay.length === 0 ? (
    <div>
      <BackButton onClick={goToPickDate} />
      <Column width="medium">
        <Heading level={2} align="center">
          <Dictionary dictKey="time.title" />
        </Heading>

        <Heading level={4} color="body.1" align="center">
          <Dictionary dictKey="time.no-timeslots-available" />
        </Heading>
        <Button
          onClick={goToPickDate}
          label={dictionary('time.date-selected.edit-link')}
        />
      </Column>
    </div>
  ) : (
    <div>
      <BackButton onClick={goToPickDate} />
      <Column width="medium" textAlign="center">
        <Heading level={2}>
          <Dictionary dictKey="time.title" />
        </Heading>
        <Heading level={4} color="body.1">
          <Dictionary dictKey="time.description" />
        </Heading>
        <Box my={2}>
          <Summary
            Icon={Calendar}
            label={dictionary('review.date.title')}
            values={calendarValues}
            onButtonClick={goToPickDate}
            buttonLabel={dictionary('review.date.edit-link')}
          />
          {timeSlotsForPickedDay.map(
            ({ id, label, remainingLabel, disabled, onClick }) => (
              <Box textAlign="center" key={id}>
                <TimeSlotButton onClick={onClick} disabled={disabled}>
                  {label}
                </TimeSlotButton>
                {remainingLabel ? (
                  <Heading level={5} color="body.4">
                    {remainingLabel}
                  </Heading>
                ) : (
                  ''
                )}
              </Box>
            ),
          )}
        </Box>
        <Heading level={4} color="body.2" weight={1}>
          <Dictionary dictKey="time.slot.walkup-callout" />
        </Heading>

        <Heading level={4} color="body.2" weight={1}>
          {dictionary('time.slot.final-callout.1')}{' '}
          <a
            href="https://www.chick-fil-a.com/timeshop"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chick&#8209;Fil&#8209;A.com/TimeShop
          </a>{' '}
          {dictionary('time.slot.final-callout.2')}
        </Heading>
      </Column>
    </div>
  )
}
