import styled, { css, breakpoints } from '@xstyled/styled-components'

export const FormWrapper = styled.div`
  position: relative;
  margin-bottom: 5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  ${breakpoints({
    md: css`
      padding: 0 150px;
    `,
  })}
`

export const FieldsWrapper = styled.div`
  max-width: 400px;
  margin: 3 auto 4;
`
