import StringMask from 'string-mask'
import countries from '../data/countries.json'

export const formatPhone = (
  phoneCountryCode?: string,
  phone?: string,
): string => {
  if (
    !phoneCountryCode ||
    !phone ||
    phoneCountryCode.length == 0 ||
    phone.length === 0
  ) {
    return ''
  }

  const country = countries.find((c) => c.countryCode === phoneCountryCode)
  if (!country) return ''
  const trimmed = phone.replace(/\D/g, '')
  const format = country.phoneFormat
  const masked =
    format && format !== '#N/A' ? new StringMask(format).apply(trimmed) : phone

  return ['+', country.dialingCode, ' ', masked].join('')
}

const getDate = (d: Date | string): Date =>
  d instanceof Date ? d : new Date(d)

const formatForTimezone = (date: Date, timeZone = 'America/New_York'): string =>
  date.toLocaleString('en-us', { timeZone })

const getHourAmPm = (date: Date): string => {
  const dateString = formatForTimezone(date)
  const [_, time, ampm] = dateString.split(' ')
  const [hours] = time.split(':')
  return [hours, ampm.toLowerCase()].join(' ')
}

export const formatStartEndTime = (
  start: Date | string,
  end: Date | string,
): string => {
  const startDate = getDate(start)
  const endDate = getDate(end)
  if (startDate.getDate() !== endDate.getDate())
    throw new Error('Start and end times must be on the same day')
  if (startDate.getTime() >= endDate.getTime())
    throw new Error('Start date must be earlier than end date')

  const startString = getHourAmPm(startDate)
  const endString = getHourAmPm(endDate)
  return [startString, '-', endString].join(' ')
}

const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const formatFullDate = (input: Date | string) => {
  const dateObj = getDate(input)
  const day = days[dateObj.getDay()]
  const month = months[dateObj.getMonth()]
  const date = dateObj.getDate()

  return `${day}, ${month} ${date}`
}

export const hyphenate = (input: string, groupBy: number): string =>
  input
    .split('')
    .reduce<string>(
      (acc, current, index) =>
        index % groupBy === 0 ? `${acc}-${current}` : `${acc}${current}`,
      '',
    )
    .replace(/(^-)|(-$)/g, '')

export const formatPhoneNumber = (
  phoneNumber: string,
  phoneCountryCode: string,
): string => `${phoneCountryCode} ${phoneNumber}`
