import gql from 'graphql-tag'
import { Group, TimeSlot } from '../../types'
import { groupFragment } from '../../queries/fragments'

export const timeSlotQuery = gql`
  query TimeSlotQuery($id: ID!) {
    timeSlot(input: { id: $id }) {
      id
      remaining
      startTime
      endTime
    }
  }
`

export interface TimeSlotQueryResponse {
  timeSlot?: TimeSlot
}

export const groupByMagicLink = gql`
  query ByMagicLink($magicLink: String!) {
    groupByMagicLink(input: { magicLink: $magicLink }) {
      group {
        ...GroupFragment
      }
    }
  }
  ${groupFragment}
`

export interface GroupQueryResponse {
  groupByMagicLink: {
    group?: Group
  }
}

export const updateReservation = gql`
  mutation UpdateReservation(
    $token: String!
    $timeSlotId: ID
    $expectedGroupSize: Int
  ) {
    updateReservation(
      input: {
        token: $token
        expectedGroupSize: $expectedGroupSize
        timeSlotId: $timeSlotId
      }
    ) {
      group {
        ...GroupFragment
      }
    }
  }
  ${groupFragment}
`

export const cancelReservationQuery = gql`
  mutation CancelReservation($groupToken: String!) {
    cancelReservation(input: { groupToken: $groupToken }) {
      messages {
        message
        isError
      }
    }
  }
`
