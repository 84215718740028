import { createGlobalStyle } from 'styled-components'
import { normalized } from './normalized'
import { fontFaces } from './fontFaces'

export const GlobalStyles = createGlobalStyle`
  ${normalized}
  ${fontFaces}

  * {
   box-sizing: border-box;
  }

html, body, #root {
margin: 0;
padding: 0;
height: 100%;
}

  body {
    margin: 0;
    padding: 20px;
padding-bottom: 0;
    font-family: ${({ theme }) => theme.fonts.sans};
  }

	button, input, select, option, textarea {
		background: white;
		font-weight: 300;
		border: none;
		outline: none;
		line-height: normal;
		padding: 0;
		border-radius: 0;
	}

	img, svg {
		max-width: 100%;
	}
svg {
  width: 100%;
}

	button {
		cursor: pointer;
    font-family: inherit;
	}

  a {
    text-decoration: none;
    color: inherit;
  }
`
