import * as React from 'react'
import { useReducer } from 'react'

/**
 * Internal State
 */

interface State {
  currentStep: string
  stepHistory: State[]
}

const GOTO = 'GOTO'
const BACK = 'BACK'

interface GoToAction {
  type: typeof GOTO
  step: string
}

interface BackAction {
  type: typeof BACK
}

type Action = GoToAction | BackAction

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case GOTO:
      return {
        ...state,
        currentStep: action.step,
        stepHistory: [...state.stepHistory, state],
      }

    case BACK:
      const lastState = state.stepHistory.length
        ? state.stepHistory[state.stepHistory.length - 1]
        : undefined
      // if no history, return state as-is
      if (!lastState) return state
      return {
        ...lastState,
      }

    default:
      return state
  }
}

/**
 * Context Value
 */

export interface SequenceContextValue {
  goToStep: (step: string) => void
  back: () => void
  currentStep: string
}

const SequenceContext = React.createContext<SequenceContextValue | undefined>(
  undefined,
)

export const SequenceConsumer = SequenceContext.Consumer

export const useSequence = () => {
  const ctx = React.useContext(SequenceContext)
  if (!ctx)
    throw new Error('useSequenceContext must be used within a SequenceProvider')
  return ctx
}

interface SequenceProps {
  children: React.ReactNode
  snapToTop?: boolean
  initialStep: string
}

export const SequenceProvider = ({ initialStep, children }: SequenceProps) => {
  if (!initialStep) throw new Error('You must provide an initialStep prop')
  const initialState = {
    currentStep: initialStep,
    stepHistory: [],
  }
  const [state, dispatch] = useReducer(reducer, initialState)
  const { currentStep, stepHistory } = state
  const canGoBack = Boolean(stepHistory.length > 0)

  const back = () => dispatch({ type: BACK })
  const goToStep = (step: string) => {
    const scrollingEl = document.scrollingElement
    if (scrollingEl) scrollingEl.scrollTop = 0
    dispatch({ type: GOTO, step })
  }

  const value = {
    back,
    goToStep,
    currentStep,
    canGoBack,
  }

  return (
    <SequenceContext.Provider value={value}>
      {children}
    </SequenceContext.Provider>
  )
}

SequenceProvider.defaultProps = {
  snapToTop: true,
}
