import * as React from 'react'
import { useFormikContext } from 'formik'
import { Field } from '../../components/Forms'
import { useSequence } from '../../components/Sequence'
import { Heading } from '../../components/Text'
import { Button } from '../../components/Button'
import { BackButton } from '../../components/Navigation'
import { FormValues } from './RegistrationSequence'
import { FieldsWrapper } from './styled'
import { Dictionary, useDictionary } from '../../providers'

const { useState, useEffect } = React

interface GroupCountProps {
  nextStep: string
}

export const GroupCount = ({ nextStep }: GroupCountProps) => {
  const { errors, setTouched, validateForm } = useFormikContext<FormValues>()
  const { goToStep } = useSequence()

  const { dictionary } = useDictionary()
  const [shouldProceed, setShouldProceed] = useState(false)
  const next = async () => {
    setTouched({
      expectedGroupSize: true,
    })
    await validateForm()
    setShouldProceed(true)
  }

  useEffect(() => {
    if (shouldProceed === false) return
    if (errors.expectedGroupSize) {
      setShouldProceed(false)
      return
    }
    goToStep(nextStep)
  }, [shouldProceed, errors])

  const goBack = () => goToStep('contactInfo')

  return (
    <div>
      <BackButton onClick={goBack} />
      <Heading align="center" level={2}>
        <Dictionary dictKey="party.title" />
      </Heading>
      <FieldsWrapper>
        <Field
          label={dictionary('party.visitors.label')}
          name="expectedGroupSize"
          required
          type="number"
          min={1}
          max={20}
        />
      </FieldsWrapper>
      <Button onClick={next} label={dictionary('global.continue')} />
    </div>
  )
}
