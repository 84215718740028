import * as React from 'react'
import styled, { Box, css } from '@xstyled/styled-components'
import { Header } from './Header'
import { Footer } from './Footer'

interface ColumnProps {
  width: 'small' | 'medium' | 'm-large' | 'large'
}

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const Column = styled(Box)`
  ${({ width }: ColumnProps) => css`
    margin: 0 auto;
    width: 100%;
    max-width: ${width === 'small'
      ? '300px'
      : width === 'medium'
      ? '420px'
      : width === 'm-large'
      ? '510px'
      : '650px;'};
  `}
`

export const ContentWrapper = styled(Box)`
  padding: 4 0 5;
  flex-grow: 1;
  position: relative;
`

interface PageWrapperProps {
  children: React.ReactNode
}

export const PageWrapper = ({ children }: PageWrapperProps) => (
  <Outer>
    <Header />
    <ContentWrapper>{children}</ContentWrapper>
    <Footer />
  </Outer>
)
