import * as React from 'react'
import LogRocket from 'logrocket'
// import Debug from 'debug'

const { useState, useEffect } = React

localStorage.debug = 'dev:*'
// const debug = Debug('dev:logging')
const debug = console.log

interface ErrorMonitorContextValue {
  identifyUser: (email: string) => void
  debug: (message: any) => void
}

const ErrorMonitorContext = React.createContext<
  ErrorMonitorContextValue | undefined
>(undefined)

export const ErrorMonitorConsumer = ErrorMonitorContext.Consumer

export const useErrorMonitor = () => {
  const ctx = React.useContext(ErrorMonitorContext)
  if (!ctx)
    throw new Error(
      'useErrorMonitorContext must be used within a ErrorMonitorProvider',
    )
  return ctx
}

interface ErrorMonitorProps {
  children: React.ReactNode
}

export const ErrorMonitorProvider = ({ children }: ErrorMonitorProps) => {
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    if (window.location.hostname !== 'localhost') {
      LogRocket.init('ulpljc/smilebooth-cfa')
      setInitialized(true)
    }
  }, [])

  const identifyUser = (email: string) => {
    if (initialized) {
      debug(`identifying user: ${email}`)
      LogRocket.identify(email)
    }
  }

  const value = {
    identifyUser,
    debug,
  }

  return (
    <ErrorMonitorContext.Provider value={value}>
      {children}
    </ErrorMonitorContext.Provider>
  )
}
