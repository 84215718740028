import styled, { css, breakpoints, Box } from '@xstyled/styled-components'

export const Outer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const Wrapper = styled.div`
  ${breakpoints({
    xs: css`
      border: 1px solid;
      border-color: primaryMain;
      padding: 3 3 30vw;
      flex-grow: 1;
      margin-bottom: 2;
      position: relative;
      text-align: center;
    `,
    md: css`
      padding: 10vh 3 180px;
    `,
  })}

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    padding: 10vh 3 280px;
  }
`

export const logoStyles = {
  width: '55px',
  position: 'absolute',
  top: '10px',
  left: '10px',
}

export const LogotypeWrapper = styled(Box)`
  max-width: 580px;
  margin: 0 auto 5;
`

export const logotypeStyles = {
  width: '100%',
  display: 'block',
}
