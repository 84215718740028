import * as React from 'react'
import styled from '@xstyled/styled-components'
import LogoType from '../assets/smilebooth_logo.svg'
import Lips from '../assets/lips.svg'

const Wrapper = styled.header`
  padding: 2;
  border-bottom: 2px solid;
  border-color: highlight.4;
  display: flex;
  align-items: center;
  justify-content: center;

  display: grid;
  grid-template-columns: 180px 1fr 180px;
`
const LipsWrapper = styled.div`
  svg {
    height: auto;
    width: 100%;
    max-height: 66px;
  }
`

const LogoTypeWrapper = styled.div`
  svg {
    height: auto;
    width: 100%;
    max-height: 86px;
  }
`

export const Header = () => (
  <Wrapper>
    <LipsWrapper>
      <Lips />
    </LipsWrapper>
    <LogoTypeWrapper>
      <LogoType />
    </LogoTypeWrapper>
  </Wrapper>
)
