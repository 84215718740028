import * as React from 'react'
import { Redirect } from 'react-router-dom'
import WelcomeLogotype from '../assets/smilebooth_logo.svg'
import { Heading } from '../components/Text'
import { Column } from '../components/Layout'
import { Footer } from '../components/Footer'
import { RegistrationFull } from './RegistrationFull'
import { useSiteData } from '../providers'
import { Outer, Wrapper, LogotypeWrapper, logotypeStyles } from './styled'
import { Dictionary } from '../providers'
import { AgeVerification } from './RegistrationSequence/AgeVerification'

export const OnlineWelcome = () => {
  const { registrationFull, fetching } = useSiteData()
  const [isConfirmed, setIsConfirmed] = React.useState<boolean | void>(
    undefined,
  )
  const confirmYes = () => setIsConfirmed(true)

  if (isConfirmed === true) {
    return <Redirect to="register" />
  }
  if (fetching) return null
  return (
    <Outer>
      <Wrapper>
        <LogotypeWrapper>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.chick-fil-a.com/timeshop"
          >
            <WelcomeLogotype style={logotypeStyles} />
          </a>
        </LogotypeWrapper>
        {registrationFull ? (
          <RegistrationFull />
        ) : (
          <>
            <Column mb={4} textAlign="center" width="large">
              <Heading level={2}>
                <Dictionary dictKey="prereg.welcome.title" />
              </Heading>
              <Heading level={4} color="body.1">
                <Dictionary dictKey="prereg.welcome.subtitle.main" />
              </Heading>
              <Heading level={4} color="body.1">
                <Dictionary dictKey="prereg.welcome.subtitle.dates" />
              </Heading>
            </Column>
            <AgeVerification onConfirm={confirmYes} />
          </>
        )}
      </Wrapper>
      <Footer />
    </Outer>
  )
}
