import { Language, Params, DictionaryData } from './DictionaryProvider'

const applyParameters = (text: string, params: Params) =>
  Object.entries(params).reduce<string>(
    (acc, [key, value]) => acc.replace(`{{ ${key} }}`, value.toString()),
    text,
  )

export const buildDictionaryReader = (
  dictionary: DictionaryData,
  defaultLanguage: Language,
) => (lang: Language) => (key: string, params?: Params): string => {
  const node = dictionary[key]
  if (!node || !node.translations) {
    console.warn(`No hay una traducción para la clave "${key}"`)
    return ''
  }
  const text = node.translations[lang] || node.translations[defaultLanguage]
  if (!text) {
    console.warn(`No hay una traducción para la clave "${key}"`)
    return ''
  }
  if (params) {
    return applyParameters(text.toString(), params)
  }
  return text ? text.toString().replace(/\n$/, '') : ''
}
