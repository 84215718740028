import * as React from 'react'
import { buildDictionaryReader } from './utils'

export interface Params {
  [key: string]: string | number
}

export const EN = 'en_us' as 'en_us'
export const ES = 'es_mx' as 'es_mx'

export type Language = typeof EN | typeof ES

export type DictionaryFunction = (key: string, parameters?: Params) => string
type DictionaryReader = (language: Language) => DictionaryFunction

interface Translation {
  translations: {
    en_us: string | number | null
    es_mx: string | number | null
  }
}

export interface DictionaryData {
  [key: string]: Translation
}

const DictionaryContext = React.createContext<
  DictionaryContextValue | undefined
>(undefined)

export const DictionaryConsumer = DictionaryContext.Consumer

export const useDictionary = () => {
  const ctx = React.useContext(DictionaryContext)
  if (!ctx)
    throw new Error(
      'useDictionaryContext must be used within a DictionaryProvider',
    )
  return ctx
}

interface DictionaryContextValue {
  dictionary: DictionaryFunction
  currentLanguage: Language
  setLanguage: (language: Language) => void
  EN: typeof EN
  ES: typeof ES
}

interface DictionaryProps {
  children: React.ReactNode
  defaultLanguage: Language
  dictionaryData: DictionaryData
}

const { useState, useMemo } = React

export const DictionaryProvider = ({
  defaultLanguage,
  dictionaryData,
  children,
}: DictionaryProps) => {
  const [currentLanguage, setCurrentLanguage] = useState<Language>(
    defaultLanguage,
  )
  const setLanguage = (l: Language) => setCurrentLanguage(l)
  const dictionaryReader = useMemo(
    () => buildDictionaryReader(dictionaryData, defaultLanguage),
    [dictionaryData, defaultLanguage],
  )
  const dictionary = dictionaryReader(currentLanguage)
  const value = {
    dictionary,
    currentLanguage,
    setLanguage,
    EN,
    ES,
  }

  return (
    <DictionaryContext.Provider value={value}>
      {children}
    </DictionaryContext.Provider>
  )
}
