import * as React from 'react'
import { useFormikContext } from 'formik'
import { CalendarField } from '../../components/Forms'
import { useSequence } from '../../components/Sequence'
import { Column } from '../../components/Layout'
import { BackButton } from '../../components/Navigation'
import { Heading } from '../../components/Text'
import { FormValues } from './RegistrationSequence'
import { timeSlotsToDays } from '../../utils'
import { useSiteData, Dictionary, useDictionary } from '../../providers'

/** Utils */
/**
 * PickDate Component
 */

export const PickDate = () => {
  const { timeSlots } = useSiteData()
  const { dictionary } = useDictionary()
  const { goToStep } = useSequence()
  const { values, setValues } = useFormikContext<FormValues>()

  const setDay = (day: number) => () => {
    setValues({ ...values, day: day.toString() })
    goToStep('pickTime')
  }

  const days = timeSlotsToDays(timeSlots, values.expectedGroupSize)

  const calendarDays = days.map(({ date, open, hasAvailableTimeSlots }) => ({
    date,
    onClick: setDay(date),
    enabled: open,
    dayIsPast: date < new Date().getDate(),
    hasAvailableTimeSlots,
  }))

  const goBack = () => goToStep('groupCount')

  const availableDays = calendarDays.filter((d) => d.hasAvailableTimeSlots)

  return (
    <div>
      <BackButton onClick={goBack} />
      <Column width="medium">
        <Heading level={2} align="center">
          <Dictionary dictKey="date.title" />
        </Heading>
        <Heading level={4} color="body.1" align="center">
          <Dictionary dictKey="date.description" />
        </Heading>
      </Column>
      <CalendarField
        name="day"
        label={dictionary('date.calendar.label')}
        days={calendarDays}
      />
      {availableDays.length === 0 ? (
        <Heading mt={2} level={3} color="red" align="center">
          <Dictionary dictKey="date.calendar.too-big" />
        </Heading>
      ) : null}
    </div>
  )
}
