import * as React from 'react'
import { NFC } from './NFC'

const { useEffect, useState } = React

interface NFCContextValue {
  nfc: NFC | null
}

const NFCContext = React.createContext<NFCContextValue | undefined>(undefined)

export const NFCConsumer = NFCContext.Consumer

export const useNFC = () => {
  const ctx = React.useContext(NFCContext)
  if (!ctx) throw new Error('useNFCContext must be used within a NFCProvider')
  return ctx
}

interface NFCProps {
  children: React.ReactNode
}

export const NFCProvider = ({ children }: NFCProps) => {
  //@ts-ignore
  const [nfc, setNfc] = useState<NFC | null>(window.nfc)

  useEffect(() => {
    const onDeviceReady = () => {
      setNfc(nfc)
    }
    document.addEventListener('deviceready', onDeviceReady, false)
    return () => document.removeEventListener('deviceready', onDeviceReady)
  }, [])

  const value = {
    nfc,
  }

  return <NFCContext.Provider value={value}>{children}</NFCContext.Provider>
}
