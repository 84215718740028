import * as React from 'react'
import { Link } from 'react-router-dom'
import styled, {
  css,
  breakpoints,
  DefaultTheme,
} from '@xstyled/styled-components'

interface ElementProps {
  to?: string
  theme: DefaultTheme
}

const Button = styled.button`
  ${({ theme }: ElementProps) => css`
    position: relative;
    margin-bottom: 2;
    color: highlight.2;
    font-size: 3;
    font-weight: 3;
    border: none;
    background: none;
    cursor: pointer;
    font-family: sans;
    padding-left: 1em;

    &:before {
      content: '';
      border-color: inherit;
      border-top: 3px solid;
      border-left: 3px solid;
      position: absolute;
      left: 0;
      top: 50%;
      width: 0.5em;
      height: 0.5em;
      transform: translateY(-0.15em) rotate(-45deg);
      transform-origin: 0 0;
    }

    ${breakpoints({
      md: css`
        position: absolute;
        top: ${theme.space[4]}px;
        left: ${theme.space[4]}px;
      `,
    })}
  `}
`

interface BackButtonProps {
  linkTo?: string
  onClick?: (value?: any) => void | Promise<void>
  label?: string
}

export const BackButton = ({ linkTo, onClick, label }: BackButtonProps) => {
  const buttonLabel = label || 'Back'
  if (linkTo)
    return (
      <Button as={Link} to={linkTo}>
        {buttonLabel}
      </Button>
    )
  return (
    <Button type="button" onClick={onClick}>
      {buttonLabel}
    </Button>
  )
}
