import * as React from 'react'
import { Provider, createClient } from 'urql'

const { useMemo } = React

interface UrqlProviderProps {
  url: string
  children: React.ReactNode
}

export const UrqlProvider = ({ url, children }: UrqlProviderProps) => {
  const client = useMemo(
    () =>
      createClient({
        url,
      }),
    [],
  )
  return <Provider value={client}>{children}</Provider>
}
