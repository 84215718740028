import { TimeSlot, Group } from '../types'
import { hyphenate, formatFullDate, formatStartEndTime } from './formatting'

export const getGroupValues = (group: Group): string[] => {
  const groupSize = group.expectedGroupSize || group.guests.edges.length || 1
  const timeSlot = group.timeSlot

  const groupValues = [
    `Ticket ID: ${hyphenate(group.reservationId, 3)}`,
    [groupSize, groupSize === 1 ? 'person' : 'people', 'in your party'].join(
      ' ',
    ),
    timeSlot ? formatFullDate(timeSlot.startTime) : 'Registered on-site',
    timeSlot ? formatStartEndTime(timeSlot.startTime, timeSlot.endTime) : '',
  ].filter((s) => s.length > 0)
  return groupValues
}

interface KeyedObject {
  [key: string]: any
}

export const isMatch = (obj1: KeyedObject, obj2: KeyedObject): boolean =>
  Object.entries(obj1).reduce<boolean>((prev, current) => {
    if (prev === false) return false
    const [key, value] = current
    return Boolean(obj2.hasOwnProperty(key) && obj2[key] === value)
  }, true)

export interface Day {
  id: string
  open: boolean
  timeSlots: TimeSlot[]
  hasAvailableTimeSlots: boolean
  date: number
}

const allDays = Array.from({ length: 21 }, (_: {}, i: number) => i + 1)

export const timeSlotsToDays = (
  timeSlots: TimeSlot[],
  groupSize: number,
): Day[] =>
  allDays.map((date) => {
    const timeSlotsInDay = timeSlots.filter(
      (ts) => new Date(ts.startTime).getDate() === date,
    )

    const availableTimeSlots = timeSlotsInDay.filter(
      (ts) => ts.remaining >= groupSize,
    )
    const hasAvailableTimeSlots = Boolean(availableTimeSlots.length > 0)
    const open = Boolean(timeSlotsInDay.length > 0)
    return {
      id: date.toString(),
      date,
      open,
      timeSlots: timeSlotsInDay,
      hasAvailableTimeSlots,
    }
  })
